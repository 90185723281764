@charset "utf-8";
@use 'variables' as v;

@media screen and (max-width: 767px) {

  /****************************
  *  共通設定
  ****************************/
  html {
    font-size: 62.5%;
    line-height: 1.5;
  }

  body {
    font-size: 1.4rem;
    color: v.$black;
    font-family: v.$serif;
    line-height: 1.7;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    outline: none;
  }

  img {
    vertical-align: bottom;
    max-width: 100%;
    height: auto;
    border-style: none;
  }

  .flex-container {
    display: flex;
    justify-content: space-between;
  }

  .object_fit_img {
    object-fit: cover;
    object-position: center center;
    font-family: 'object-fit: cover;';
  }

  .pc,
  .pc-header {
    display: none;
  }

  /****************************
  *  共通パーツ
  ****************************/
  /* ヘッダー */
  header {
    .sp-header {
      margin: 10px 0;

      .sp-wrap {
        width: 100%;
        padding: 0 0 0 10px;
        display: flex;
        align-items: center;

        .logo {
          width: 224px;
        }

        .toggle {
          width: 45px;
          height: 45px;
          position: fixed;
          top: 10px;
          right: 10px;
          display: block;
          background: v.$main-color;
          border-radius: 5px;
          z-index: 999;

          span {
            width: 31px;
            border-top: 2px solid v.$white;
            position: absolute;
            top: 11px;
            left: 7px;
            transition: .3s all;

            &:nth-of-type(2) {
              top: 21px;
            }

            &:nth-of-type(3) {
              top: 31px;
            }
          }

          &.active {
            span {
              top: 21px;
              transform: rotate(45deg);

              &:nth-of-type(2) {
                transform: rotate(-45deg);
              }
            }
          }
        }
      }

      .drawer {
        width: 290px;
        height: 100vh;
        overflow: scroll;
        position: fixed;
        top: 0;
        right: -300px;
        background: v.$white;
        padding: 60px 0 100px;
        transition: .3s all;
        z-index: 998;
        border-left: 5px solid v.$main-color;

        &.active {
          right: 0;
        }

        > p {
          text-align: center;
          line-height: 1.6;
          margin: 0 0 20px;
        }

        .contact-wrap {
          .tel {
            display: block;
            width: 235px;
            border-radius: 7px;
            color: v.$white;
            font-size: 1.4rem;
            text-align: center;
            background: v.$main-color;
            font-family: v.$sans;
            padding: 6px 0;
            margin: 0 auto 7px;

            span {
              background: url(../images/top/icon_phone.svg) left center no-repeat;
              background-size: 21px, 21px;
              font-size: 2.6rem;
              padding: 0 0 0 25px;
              color: v.$white;
              font-family: v.$sans;
            }
          }

          .mail {
            display: block;
            width: 235px;
            color: v.$white;
            text-align: center;
            background: v.$sub-color;
            border-radius: 7px;
            padding: 17px 0 20px;
            font-family: v.$sans;
            margin: 0 auto 20px;

            span {
              padding: 0 0 3px 30px;
              background: url(../images/top/icon_mail.svg) left center no-repeat;
              background-size: 23px, 23px;
              font-size: 1.8rem;
              color: v.$white;
              font-weight: bold;
              font-family: v.$sans;
            }
          }
        }

        ul {
          width: 100%;
          margin: 0 auto;

          li {
            border-top: 1px solid v.$main-color;
            border-bottom: 1px solid v.$main-color;

            & + li {
              border-top: none;
            }

            a {
              display: block;
              width: 100%;
              padding: 12px;
            }

            span {
              display: block;
              width: 100%;
              padding: 12px;
              color: v.$main-color;
            }

            .sp-nav_sub {
              li {
                border: none;
                padding: 0 0 0 15px;

                a {
                  position: relative;
                  background: rgba(0, 64, 152, 0.1);
                  border-top: 1px solid rgba(0, 64, 152, 0.3);
                  border-left: 5px solid rgba(0, 64, 152, 0.3);
                }
              }
            }
          }
        }
      }
    }

    .gnav_scroll {
      opacity: 0;
      visibility: hidden;
      display: none;
    }

    .black {
      display: none;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, .5);
      z-index: 20;

      &.active {
        display: block;
      }
    }
  }
  /* フッター */
  footer {
    width: 100%;
    background: url(../images/top/bg_cloth.jpg);
    padding: 60px 0 0;

    #pageTop {
      width: 45px;
      height: 45px;
      display: block;
      position: fixed;
      border-radius: 50%;
      bottom: 30px;
      right: 10px;
      z-index: 99;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .flex-container {

      .adress {
        margin: 0 auto 50px;
        width: 250px;

        img {
          width: 100%;
          margin: 0 0 15px;
        }

        p {
          line-height: 1.5;
          padding: 0 0 0 55px;
          font-family: v.$sans;
          display: block;

        }
      }
    }

    small {
      display: block;
      width: 100%;
      padding: 10px 0;
      text-align: center;
      color: v.$white;
      background: v.$main-color;
      font-family: v.$sans;
    }
  }
  /* インナー */
  .innerS {
    width: 94vw;
    margin: 0 auto;
  }
  /* インナー */
  .innerM {
    width: 94vw;
    margin: 0 auto;
  }
  /* インナー */
  .innerL {
    width: 94vw;
    margin: 0 auto;
  }
  /* 下層タイトル */
  .title {
    height: 200px;
    padding: 72px 0 0;
    background: url('../images/shop/shop_mv.jpg') no-repeat center center / cover;
    text-align: center;

    .title_h {
      font-size: 2rem;
      color: v.$main-color;
      font-weight: 700;
      background: rgba(255, 255, 255, 0.7);
      padding: 10px 50px;
      display: inline-block;
      border: 1px solid v.$main-color;
      letter-spacing: 2px;
    }
  }
  /* 見出し（青背景＋白字） */
  .h01 {
    font-size: 2rem;
    color: v.$white;
    background: v.$main-color;
    text-align: center;
    padding: 7px 10px;
    font-family: v.$os;
    font-weight: 700;
    margin: 100px 0 35px;
    letter-spacing: 2px;
  }
  /* 見出し（青字＋下線） */
  .h02 {
    font-size: 2.2rem;
    color: v.$main-color;
    text-align: center;
    padding: 7px 10px;
    font-family: v.$serif;
    font-weight: 700;
    border-bottom: 1px solid v.$main-color;
    display: inline-block;
    margin: 0 auto 20px;
  }
  /* テキスト */
  .txt01 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.78;
    text-align: center;

    strong {
      color: v.$main-color;
      font-weight: 700;
    }
  }
  /* ボタン1 */
  .btn01 {
    text-align: center;

    a {
      position: relative;
      background: v.$sub-color;
      color: v.$white;
      padding: 20px 30px 20px 20px;
      width: 100%;
      display: block;
      font-family: v.$sans;
      border: 1px solid v.$sub-color;
      margin: 0 auto;

      &::after {
        position: absolute;
        content: '';
        right: 20px;
        top: 50%;
        width: 8px;
        height: 8px;
        border-right: 1px solid v.$white;
        border-bottom: 1px solid v.$white;
        transform: translateY(-50%) rotate(-45deg);
      }

      &:hover {
        opacity: 1;
        color: v.$sub-color;
        background: v.$white;

        &::after {
          border-right: 1px solid v.$sub-color;
        border-bottom: 1px solid v.$sub-color;
        }
      }
    }
  }
  /* ページネーション */
  .wp-pagenavi {
    margin: 30px auto 80px;
    font-size: 1.8rem;
    text-align: center;
    font-family: v.$sans;

    .page {
      display: inline-block;
      width: 30px;
      height: 50px;
      background: v.$white;
      color: v.$main-color;
      text-align: center;
      line-height: 48px;
      margin: 0 5px 0 0;
      border: 1px solid v.$main-color;
    }

    .current {
      display: inline-block;
      width: 30px;
      height: 50px;
      background: v.$main-color;
      color: v.$white;
      text-align: center;
      line-height: 48px;
      margin: 0 5px 0 0;
      border: 1px solid v.$main-color;
    }

    .previouspostslink,
    .nextpostslink {
      display: inline-block;
      width: 30px;
      height: 50px;
      color: v.$main-color;
      text-align: center;
      line-height: 48px;
      font-weight: 700;
      margin: 0 5px 0 0;
      border: 1px solid v.$main-color;

      &:hover {
        opacity: 1;
        background: v.$main-color;
        color: v.$white;
      }
    }

    .pages {
      border: 1px solid v.$main-color;
      color: v.$main-color;
      height: 50px;
      padding: 0 15px;
      display: inline-block;
      line-height: 48px;
      margin: 0 10px 0 0;
    }
  }
  /* クリーニングコース */
  .lnav {
    margin: 60px 0 0;

    ul {
      li {
        margin: 0 0 10px 0;

        a {
          display: block;
          color: v.$white;
          background: v.$main-color;
          font-size: 1.5rem;
          border-radius: 10px;
          padding: 10px 5px;
          border: 1px solid v.$main-color;
        }
      }
    }
  }
  /* 汎用スタイル */
  .gen {
    text-align: center;

    h2 {
      font-size: 2.4rem;
      color: v.$main-color;
      padding: 7px 10px;
      font-family: v.$serif;
      font-weight: 700;
      border-bottom: 1px solid v.$main-color;
      display: inline-block;
      margin: 0 auto 20px;
    }

    h3 {
      position: relative;
      font-size: 2rem;
      color: v.$main-color;
      font-weight: 700;
      padding: 0 0 0 20px;
      margin: 0 0 10px 0;
      text-align: left;

      &::before {
        position: absolute;
        left: 0;
        top: 12px;
        content: '';
        width: 15px;
        height: 15px;
        border-radius: 30px;
        background: v.$main-color;
      }
    }

    p {
      font-size: 1.6rem;
      text-align: left;
      margin: 20px 0;
    }

    ul, ol {
      margin: 20px 0;

      li {
        font-size: 1.5rem;
        text-align: left;
      }
    }
  }
  /* フッター バナー */
  .bnr {
    margin: 0 auto 50px;

    li {
      width: 100%;
      margin: 10px auto;

      a {
        display: block;
        background: v.$main-color;
        color: v.$white;
        text-align: center;
        padding: 5px 10px;
        border: 1px solid v.$main-color;

        &:hover {
          opacity: 1;
          background: v.$white;
          color: v.$main-color;
        }
      }
    }
  }
  

  /****************************
  *  ページ
  ****************************/
  /*** トップページ ****************************************/
  #top {
    .main-visual {
      width: 100%;
      position: relative;
      height: 500px;
  
      .swiper-container {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 500px;
        overflow: hidden;
        
        .swiper-slide {  
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 23% center;
          }
        }
      }
  
      .flex-container {
        width: 90%;
        height: auto;
        position: absolute;
        bottom: 35px;
        height: 270px;
        left:0;
        right: 0;
        margin: 0 auto;
        align-items: flex-end;
        z-index: 10;
  
        .text-wrap {
          -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
          margin: 0 auto 0 0;
          display: flex;
          flex-direction: column;
  
          p {
            background: v.$white;
            color: v.$main-color;
            font-size: 2.4rem;
            display: inline-block;
            font-weight: bold;
            line-height: 1.6;
            padding: 15px 5px;
            margin: 0 10px 0 0;
          }
        }
  
        .btn {
          width: 120px;
        }
      }
    }
  
    .catch {
      padding: 60px 8px;
  
      h2 {
        font-size: 1.8rem;
        font-weight: bold;
        text-align: center;
        line-height: 1.8;
        margin: 0 0 35px;
        color: v.$main-color;
      }
  
      p {
        font-weight: 700;
        text-align: center;
        line-height: 2;
      }
    }
  
    .reason {
      padding: 60px 0;
      background: url(../images/top/bg_cloth.jpg);
  
      h2 {
        display: block;
        width: 212px;
        text-align: center;
        font-weight: 700;
        font-size: 2.5rem;
        color: v.$main-color;
        position: relative;
        margin: 0 auto 85px;
  
        &::before {
          content: '';
          position: absolute;
          width: 20vw;
          height: 10px;
          background: url(../images/top/heading_line.png) repeat-x;
          left: -20vw;
          top: 10px;
        }
  
        &::after {
          content: '';
          position: absolute;
          width: 20vw;
          height: 10px;
          background: url(../images/top/heading_line.png) repeat-x;
          right: -20vw;
          top: 10px;
        }
      }
  
      .flex-container {
        width: 80%;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
  
        section {
          width: 300px;
          padding: 20px 16px 30px;
          margin: 0 0 50px;
          background: v.$white;
          position: relative;
  
          h3 {
            font-size: 2rem;
            font-weight: 700;
            margin: 0 0 20px;
            color: v.$main-color;
            padding: 0 0 0 110px;
          }
  
          img {
            margin: 0 0 25px;
          }
  
          p {
            color: v.$main-color;
            line-height: 1.8;
            font-weight: 700;
            font-size: 1.4rem;
          }
  
          &::before {
            position: absolute;
            content: '';
            width: 80px;
            height: 80px;
            border-radius: 50%;
            top: -25px;
            left: 15px;
          }
  
          &:nth-of-type(1) {
            &::before {
              background: url(../images/top/reason_01.svg) center center no-repeat;
            }
          }
  
          &:nth-of-type(2) {
            &::before {
              background: url(../images/top/reason_02.svg) center center no-repeat;
            }
          }
  
          &:nth-of-type(3) {
            &::before {
              background: url(../images/top/reason_03.svg) center center no-repeat;
            }
          }
        }
      }
    }
  
    .movie {
      padding: 30px 0 60px;
      background: url(../images/top/bg_movie.jpg) 0 0 no-repeat;
  
      h2 {
        width: 200px;
        text-align: center;
        margin: 0 auto 18px;
      }
  
      .global_wrapper {
        width: 280px;
        margin: 0 auto;
      }
  
      .youtube {
        position: relative;
        width: 280px;
        height: 158px;
        background: #000;
  
        img {
          width: 100%;
          height: 100%;
          transition: .3s all;
  
        }
  
        iframe {
          padding: 0;
          width: 280px;
          height: 158px;
        }
  
      }
  
      .player {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
  
      .thumb {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
    }
  
    .voice {
      padding: 60px 0 0;
      width: 100%;
      overflow: hidden;
  
      h2 {
        display: block;
        width: 90px;
        font-size: 1.8rem;
        color: v.$main-color;
        position: relative;
        margin: 0 auto 23px;
  
        &::before,
        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 270px;
          height: 2px;
          background: v.$main-color;
          top: 14px;
          left: -293px;
        }
  
        &::after {
          left: 110px;
        }
      }
  
      .flex-container {
        width: 100%;
        padding: 0 20px;
        flex-wrap: wrap;
        margin: 0 auto 20px;
        justify-content: center;
  
        figure {
          width: 333px;
          padding: 26px 22px;
          border: 2px solid v.$main-color;
          margin: 0 10px 30px;
  
          figcaption {
            font-size: 1.8rem;
            font-weight: bold;
            margin: 0 0 8px;
            color: v.$main-color;
          }
  
          img {
            margin: 0 0 5px;
          }
  
          p {
            line-height: 1.8;
          }
        }
      }
  
      a {
        display: block;
        margin: 0 auto 112px;
        font-family: v.$sans;
        color: v.$main-color;
        width: 204px;
        text-align: center;
        line-height: 2.4;
        border: 2px solid v.$main-color;
      }
    }
  
    .news {
      width: 100%;
      height: 570px;
      position: relative;
      margin: 0 auto;
  
      .speech-balloon {
        width: 200px;
        position: absolute;
        top: -32px;
        left: 10px;
        height: 70px;
        font-weight: bold;
        font-size: 1.8rem;
        text-align: center;
        color: v.$white;
        background: v.$main-color;
        padding: 23px 0 0;
        border-radius: 3px;
        box-shadow: 5px 5px 8px #abb1b7;
        z-index: 1;
  
        &::before {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0px 13px 15px 0;
          border-color: transparent v.$main-color transparent transparent;
          bottom: -14px;
          left: 50%;
        }
      }
  
      .news-table {
        width: 90%;
        position: absolute;
        top: 4px;
        left: 5%;
        padding: 60px 20px 22px;
        background: #f5f3f2;
  
        ul {
          margin: 0 0 22px;
  
          li {
            border-bottom: 1px solid v.$main-color;
  
            a {
              display: flex;
              flex-direction: column;
              width: 100%;
              padding: 18px 0;
  
              time {
                padding: 0 0 12px 0;
                font-family: v.$sans;
              }
  
              p {
                font-family: v.$sans;
              }
            }
          }
        }
  
        .btn {
          display: block;
          width: 126px;
          color: v.$white;
          background: v.$main-color;
          border-radius: 8px;
          font-family: v.$sans;
          padding: 8px 15px;
          margin: 0 0 0 auto;
        }
      }
    }
  
    .info {
      background: url(../images/top/bg_info.jpg) 0 0 no-repeat;
      background-size: cover;
      padding: 30px 0 87px;
  
      h2 {
        text-align: center;
        color: v.$white;
        font-size: 2.4rem;
        margin: 0 0 30px;
      }
  
      .info-inner {
        width: 90%;
        margin: 0 auto;
        background: v.$white;
        padding: 30px 4%;
        flex-direction: column;
        justify-content: center;
  
        .img-wrap {
          width: 100%;
          max-width: 435px;
          margin: 0 auto 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
  
          img {
            width: 35%;
  
            &:first-child {
              width: 60%;
            }
          }
        }
  
        .about {
          width: 100%;
  
          dl {
            width: 100%;
            padding: 15px 0;
            display: flex;
            flex-direction: column;
            border-top: 1px solid v.$main-color;
  
            &:first-of-type {
              border-top: none;
              padding: 0 0 15px;
            }
  
            dt {
              color: v.$main-color;
              font-family: v.$sans;
              font-size: 1.4rem;
              margin: 0 0 7px;
  
            }
  
            dd {
              font-family: v.$sans;
              line-height: 1.6;
              font-size: 1.4rem;
            }
          }
  
          .title2 {
            margin: 40px 0;
            border-top: 1px solid #e4789f;
            border-bottom: 1px solid #e4789f;
            padding: 13px 0;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
  
            img {
              margin: 0 10px 0;
              width: 93px;
            }
  
            p {
              line-height: 1.8;
              font-family: v.$sans;
              font-size: 1.3rem;
              margin: 0;
            }
          }
        }
      }
    }
  
    .xo-event-calendar {
      width: 90%;
      margin: 0 auto;
  
      table {
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 0;
        margin: 0;
        padding: 0;
        width: 100%;
        background-color: #fff;
        color: #333;
  
  
        tr {
          display: table-row;
        }
  
        td,
        th {
          display: table-cell;
          padding: 0;
        }
      }
  
      position: relative;
  
      .xo-month {
        position: relative;
      }
  
      table.xo-month {
        margin: 0 0 8px 0;
        padding: 0;
        border: 1px solid v.$white;
        border-width: 1px 0 1px 1px;
  
        th,
        td {
          padding: 0;
          border: 2px solid v.$white;
        }
  
        > caption {
          /*background-color: #fff;*/
  
          caption-side: top;
          text-align: center;
        }
  
        .month-header {
          margin: 2px 0 11px;
          display: flex;
          justify-content: space-between;
  
          > p {
            display: inline-block;
            text-align: right;
            font-size: 120%;
            vertical-align: middle;
            line-height: 28px;
            font-family: v.$sans;
            color: v.$main-color;
          }
        }
  
        button {
          margin: 0;
          padding: 0;
          color: #333;
          background-color: transparent;
          border: 0;
          box-shadow: none;
          text-shadow: none;
          cursor: pointer;
          width: 28px;
  
          &[disabled] {
            opacity: 0.3;
            cursor: default;
          }
  
          > span {
            width: 28px;
            height: 28px;
            line-height: 28px;
            font-size: 22px;
            text-align: center;
            vertical-align: middle;
          }
  
          span {
            &.nav-prev {
              display: inline-block;
              background: url("../images/top/nav-prev.svg") no-repeat center center;
              background-size: 14px 18px;
              width: 28px;
              height: 28px;
              font-size: 0;
            }
  
            &.nav-next {
              display: inline-block;
              background: url("../images/top/nav-next.svg") no-repeat center center;
              background-size: 14px 18px;
              width: 28px;
              height: 28px;
              font-size: 0;
            }
          }
        }
  
        .month-prev {
          float: left;
        }
  
        .month-next {
          float: right;
        }
  
        > thead th {
          border-width: 0 1px 0 0;
          padding: 1px 0 8px;
          text-align: center;
          font-size: 100%;
          font-weight: bold;
          font-family: v.$sans;
  
          &.sunday {
            color: #d00;
          }
  
          &.saturday {
            color: #00d;
          }
        }
  
        .month-week {
          position: relative;
          padding: 0;
          border: 0;
          width: 100%;
          overflow: hidden;
  
          table {
            border: 0;
            margin: 0;
            padding: 0;
          }
        }
  
        .month-dayname {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          border-width: 1px 0 0 1px;
  
          td {
            border-width: 1px 1px 0 0;
            padding: 0;
  
            div {
              height: 1000px;
              padding: 14px 0 0 0;
              text-align: center;
              font-size: 100%;
              line-height: 1.2em;
              font-family: v.$sans;
  
              &.other-month {
                opacity: 0.6;
              }
  
              &.today {
                color: #00d;
                font-weight: bold;
              }
            }
          }
        }
  
        .month-dayname-space {
          position: relative;
          top: 0;
          height: 1.5em;
        }
  
        .month-event {
          position: relative;
          top: 0;
          background-color: transparent;
  
          td {
            padding: 0 1px 2px 1px;
            border-width: 0 1px 0 0;
          }
        }
  
        .month-event-space {
          height: 1.5em;
          background-color: transparent;
  
          td {
            border-width: 0 1px 0 0;
          }
        }
  
        .month-event tr,
        .month-event-space tr {
          background-color: transparent;
        }
  
        .month-event-title {
          padding: 0 3px;
          display: block;
          color: #666;
          text-align: left;
          font-size: 0.8em;
          background-color: #ccc;
          border-radius: 3px;
          white-space: nowrap;
          overflow: hidden;
        }
      }
  
      .holiday-titles {
        display: flex;
        flex-direction: column;
  
        p.holiday-title {
          margin: 17px 28px 0 0;
          padding: 2px 0;
          font-size: 90%;
          vertical-align: middle;
          font-family: v.$sans;
  
          span {
            margin: 0 6px 0 0;
            padding: 5px 0 5px 32px;
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  
    .widget .xo-event-calendar table.xo-month > thead th {
      font-size: 90%;
    }

    .xo-event-calendar .loading-animation {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -20px 0 0 -20px;
    }
  
    .xoec-loading {
      .xo-months {
        opacity: 0.5;
      }
  
      .loading-animation {
        width: 40px;
        height: 40px;
        border: 5px rgba(0, 0, 0, 0.2) solid;
        border-top: 5px #4285f4 solid;
        border-radius: 50%;
        -webkit-animation: loadingCircRot .8s infinite linear;
        animation: loadingCircRot .8s infinite linear;
      }
    }
  
    .xo-event-meta {
      padding: 3.8461% 0;
  
      span {
        display: block;
      }
    }

    html[lang="ja"] .xo-event-calendar .dashicons {
      font-family: dashicons !important;
    }
  
    .btn-wrap {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      margin: 50px auto;
  
      a {
        width: 300px;
        height: 100px;
        display: block;
        margin: 10px;
  
        .btn_tel {
          width: 100%;
          height: 100%;
          background: v.$main-color;
          padding: 19px 0 0;
          box-shadow: 0 9px 0 #1b1c80;
          transition: .3s all;
  
          p {
            font-size: 1.4rem;
            color: v.$main-color;
            text-align: center;
            display: block;
            width: 200px;
            background: v.$white;
            margin: 0 auto 7px;
            position: relative;
            font-family: v.$sans;
            border-radius: 5px;
            line-height: 1.6;
            padding: 0 0 3px;
  
            &::before {
              content: '';
              position: absolute;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 14px 6px 0 6px;
              border-color: v.$white transparent transparent transparent;
              bottom: -14px;
              left: 50%;
              transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
            }
          }
  
          div {
            color: v.$white;
            font-weight: bold;
            font-size: 3rem;
            font-family: v.$sans;
            text-align: center;
  
            img {
              width: 30px;
              margin: 0 5px 0 0;
              vertical-align: baseline;
            }
          }
        }
  
        .btn_web {
          width: 100%;
          height: 100%;
          background: #00b9ef;
          padding: 19px 0 0;
          box-shadow: 0 9px 0 #0099d9;
          transition: .3s all;
  
          p {
            font-size: 1.4rem;
            color: #00b9ef;
            text-align: center;
            display: block;
            width: 200px;
            background: v.$white;
            margin: 0 auto 7px;
            position: relative;
            font-family: v.$sans;
            border-radius: 5px;
            line-height: 1.6;
            padding: 0 0 3px;
  
            &::before {
              content: '';
              position: absolute;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 14px 6px 0 6px;
              border-color: v.$white transparent transparent transparent;
              bottom: -14px;
              left: 50%;
              transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
            }
          }
  
          div {
            color: v.$white;
            font-weight: bold;
            font-size: 2.6rem;
            font-family: v.$sans;
            text-align: center;
  
            img {
              width: 30px;
              margin: 0 5px 0 0;
              vertical-align: baseline;
            }
          }
        }
  
        .btn_line {
          background: #00b900;
          width: 100%;
          height: 100%;
          padding: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-shadow: 0 9px 0 #009245;
          transition: .3s all;
  
          img {
            width: 70px;
          }
  
          div {
            width: 190px;
  
            p {
              font-weight: bold;
              font-size: 2.3rem;
              color: v.$white;
              margin: 0 0 10px;
            }
  
            img {
              width: 100%;
            }
          }
        }
      }
    }
  
    .access {
      h2 {
        background: #eae5e3;
        text-align: center;
        color: v.$main-color;
        font-size: 2.1rem;
        font-family: v.$sans;
        letter-spacing: 1px;
        padding: 10px 0;
      }
  
      .map-wrap {
        width: 100%;
        height: 300px;
  
        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  /*** 店舗案内 ********************************************/
  #shop {
    .info {
      table {
        width: 100%;
        border: 1px solid v.$main-color;

        tr {
          th {
            width: 84px;
            background: v.$bg-blue;
            padding: 10px 10px;
            border-right: 1px solid v.$main-color;
            border-bottom: 1px solid v.$main-color;
            text-align: center;
            font-size: 1.4rem;
          }
          td {
            background: v.$white;
            padding: 10px 15px;
            border-bottom: 1px solid v.$main-color;
          }
        }
      }
    }

    .prof {
      .prof_cnt {
        figure {
          width: 100%;
          height: auto;
          margin: 0 auto;
  
          img {
            width: 100%;
            height: 50vw;
            object-fit: cover;
            object-position: center;
          }
        }
  
        .prof_cnt_txt {
          width: 100%;
          line-height: (28 / 16);

          p {
            margin: 20px 0 0;
          }
          
          .pct_name {
            text-align: right;
            margin: 10px 0 0;
            font-weight: 700;

            span {
              font-size: 2.2rem;
            }
          }
        }
      }
    }

    .media {
      .media_cnt {
        .media_cnt_img {
          width: 100%;

          figure {
            @include v.obf;
            width: 100%;
            height: 260px;
          }

          a {
            background: v.$main-color;
            color: v.$white;
            padding: 12px 10px;
            display: block;
            font-family: v.$sans;
            text-align: center;
            font-size: 1.4rem;
            border-radius: 3px;
            margin: 10px 0;
          }
        }
        .media_cnt_txt {
          p {
            margin: 20px 0;
          }
        }
      }
    }
    
    .access {
      .access_map {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 430px;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .cal {
      padding: 0 0 100px;

      .xo-event-calendar {
        .xo-months {
          .xo-month {
            border: none;

            .month-header {
              span {
                color: v.$main-color;
                font-family: v.$sans;
                font-weight: 700;
                margin: 0 0 10px;
              }
            }

            thead {
              font-weight: 700;

              tr {
                th {
                  font-family: v.$sans;
                  border: none;
                  padding: 10px 0;
                }
              }
            }

            tbody {
              tr {
                td {
                  border-bottom: 4px solid v.$white;
                  border-right: 4px solid v.$white;
                  border-top: none;
                  background: v.$bg-gray;

                  div {
                    border: none;
                    font-family: v.$sans;
                    font-weight: 700;
                    text-align: center;
                    padding: 12px 5px 6px;
                    font-size: 1.6rem;
                  }

                  &:last-child {
                    border-right: none;
                  }
                }
              }
            }
          }
        }
        .holiday-titles {
          display: flex;
          margin: 15px 0 0;

          .holiday-title {
            font-family: v.$sans;
            font-size: 1.4rem;
            margin: 0 30px 0 0;
            font-weight: 700;

            span {
              border: none;
              display: inline-block;
              width: 30px;
              height: 30px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  /*** 当店のこだわり ***************************************/
  #about {
    .commit {
      text-align: center;
      padding: 80px 0 60px;
      background: url('../images/about/bg_cloth_01.jpg') repeat center center;

      ul {
        text-align: left;
        padding: 0 10px;
        margin: 40px 0 0;

        li {
          width: 100%;
          margin: 0 0 30px;

          h3 {
            display: flex;
            align-items: center;
            margin: 0 0 6px;

            span {
              &:nth-of-type(1) {
                font-family: v.$dm;
                font-size: 4rem;
                color: v.$white;
                background: v.$main-color;
                border-radius: 50px;
                display: inline-block;
                width: 60px;
                height: 60px;
                text-align: center;
                margin: 0 10px 0 0;
                font-weight: 400;
              }
              &:nth-of-type(2) {
                color: v.$main-color;
                font-size: 1.8rem;
                font-weight: 700;
                flex: 1;
              }
            }
          }

          figure {
            width: 100%;
            height: 48vw;
            margin: 0 0 20px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }

          p {
            margin: 20px 0 0;
            line-height: (30 / 16);
            font-size: 1.5rem;

            br {
              display: none;
            }
          }
        }
      }
    }

    .feature {
      text-align: center;
      padding: 80px 0;

      p {
        font-size: 1.4rem;
        font-weight: 400;
      }

      ul {
        margin: 40px 0 0;

        li {
          font-size: 1.6rem;
          background: #eaf6fd;
          width: 100%;
          margin: 0 0 30px;
          padding: 20px 20px;

          h3 {
            color: v.$main-color;
            font-size: 1.8rem;
            margin: 0 0 20px;
            font-weight: 700;
          }

          p {
            font-weight: 400;
            text-align: left;
            font-size: 1.6rem;
            margin: 20px 0;
            line-height: (30 / 16);
            font-size: 1.5rem;
          }

          figure {
            width: 100%;
            height: 48vw;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }
  }

  /*** ご利用シーン *****************************************/
  #scene {
    .intro {
      text-align: center;
      padding: 60px 0;

      h3 {
        text-align: left;
        color: v.$main-color;
        font-size: 2rem;
        font-weight: 700;
        margin: 50px 0 10px;
      }

      .intro_cnt {
        figure {
          width: 100%;
          height: auto;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .intro_cnt_txt {
          background: #f5f3f2;
          flex: 1;
          text-align: left;
          padding: 20px;
          line-height: (28 / 16);
        }
      }

      .intro_tbls {
        .intro_tbls_one {
          width: 100%;

          table {
            border: 1px solid v.$main-color;
            width: 100%;
            font-family: v.$sans;

            tbody {
              th {
                background: v.$bg-blue;
                border-bottom: 1px solid v.$main-color;
                padding: 10px 20px;
                width: 60%;
              }
              td {
                border-bottom: 1px solid v.$main-color;
                padding: 10px 20px;
                text-align: left;
                width: 40%;
              }
            }
          }

          .ito_note {
            li {
              font-size: 1.2rem;
              text-align: left;
              margin: 5px 0;
              font-family: v.$sans;

              span {
                color: v.$main-color;
              }
            }
          }
        }
      }
    }

    .ex {
      padding: 60px 0;
      background: v.$bg-light;

      .ex_li {
        li {
          width: 100%;
          margin: 0 0 10px;

          h3 {
            position: relative;
            font-size: 1.8rem;
            color: v.$main-color;
            font-weight: 700;
            padding: 0 0 0 20px;
            margin: 0 0 10px 0;

            &::before {
              position: absolute;
              left: 0;
              top: 7px;
              content: '';
              width: 15px;
              height: 15px;
              border-radius: 30px;
              background: v.$main-color;
            }
          }

          .ex_li_img {
            display: flex;
            justify-content: space-between;

            figure {
              width: 43vw;

              img {
                width: 100%;
                height: 43vw;
                object-fit: cover;
                object-position: center;
              }

              figcaption {
                font-size: 1.4rem;
                margin: 10px 0;
              }

              &:nth-of-type(2) {
                position: relative;

                &::before {
                  content: '';
                  position: absolute;
                  top: 32%;
                  left: -12%;
                  border-left: 3vw solid v.$main-color;
                  border-top: 3vw solid transparent;
                  border-bottom: 3vw solid transparent;
                }
              }
            }
          }
        }
        
      }
    }
  }

  /*** 集配・宅配 ********************************************/
  #delivery {
    .intro {
      padding: 80px 0 0;
      text-align: center;
    }

    .about {
      text-align: center;

      .about_txt {
        text-align: left;
        margin: 40px 0 0;
      }
    }

    .howto {
      text-align: center;

      .howto_li {
        margin: 30px auto 50px;

        a {
          color: v.$white;
          font-family: v.$sans;
          display: block;
          width: 100%;
          padding: 5px 10px 3px;
          margin: 15px 0;

          > span {
            position: relative;
            font-size: 1.2rem;
            background: v.$white;
            border-radius: 5px;
            font-weight: 700;
            padding: 2px 13px 3px;

            &::after {
              content: '';
              position: absolute;
              bottom: -7px;
              left: 50%;
              border-top: 10px solid v.$white;
              border-right: 5px solid transparent;
              border-left: 5px solid transparent;
            }
          }
          p {
            span {
              font-size: 2.9rem;
              background: none;
              color: v.$white;
              font-weight: 700;
              vertical-align: middle;
            }
            img {
              vertical-align: middle;
            }
          }

          &.howto_li_tel {
            background: v.$main-color;
            color: v.$main-color;
            box-shadow: 0 7px 0 #1b1c80;

            p {
              img {
                width: 24px;
              }
            }
          }
          &.howto_li_web {
            background: v.$sub-color;
            color: v.$sub-color;
            box-shadow: 0 7px 0 #0099d9;

            p {
              margin: 9px 0 0;

              img {
                width: 23px;
              }
              span {
                font-size: 2rem;
              }
            }
          }
          &.howto_li_fax {
            background: v.$accent-color;
            color: v.$accent-color;
            box-shadow: 0 7px 0 #009287;

            p {
              img {
                width: 18px;
              }
            }
          }
        }
      }

      .howto_pdf {
        border: 2px solid v.$main-color;
        padding: 10px 20px 15px;
        width: 100%;
        margin: 50px auto;
        
        p {
          margin: 5px 0 10px;
          color: v.$main-color;
          font-weight: 700;
        }
        a {
          background: v.$main-color;
          color: v.$white;
          border-radius: 7px;
          display: inline-block;
          font-family: v.$sans;
          padding: 10px 25px;
          width: 100%;
        }
      }

      .howto_type {
        .howto_type_one {
          width: 100%;
          margin: 20px 0;

          h3 {
            background: v.$bg-light;
            font-size: 2rem;
            color: v.$main-color;
            text-align: left;
            padding: 3px 10px;
            font-weight: 700;
          }

          p {
            text-align: left;
            margin: 15px 0;
          }

          .hto_txt {
            text-align: left;
            background: v.$bg-gray2;
            font-size: 1.4rem;
            padding: 8px 25px;
            min-height: 138px;
          }
        }
      }
    }

    .flow {
      text-align: center;

      .flow_li {
        padding: 50px 20px;
        background: url('../images/about/bg_cloth_01.jpg') repeat center center;
        margin: 40px auto;

        li {
          position: relative;
          background: v.$white;
          box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
          margin: 0 0 40px;
          width: 100%;
          padding: 20px 15px 30px;

          .flow_li_num {
            position: absolute;
            top: -20px;
            left: 0;
            font-family: v.$dm;
            font-size: 3.5rem;
            color: v.$white;
            background: v.$main-color;
            border-radius: 50px;
            display: inline-block;
            width: 56px;
            height: 56px;
            text-align: center;
            margin: 0 15px 0 0;
            font-weight: 400;
          }

          h3 {
            color: v.$main-color;
            font-size: 1.6rem;
            font-weight: 700;
            text-align: left;
            padding: 0 0 10px 50px;
          }

          figure {
            width: 100%;
            height: 40vw;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }

          p {
            font-size: 1.4rem;
            text-align: left;
            margin: 10px 0;
            letter-spacing: -1px;
          }
        }
      }
    }

    .route {
      padding: 0 0 130px;

      .route_txt {
        p {
          margin: 20px 0;
        }
      }
    }
  }

  /*** お客様の声 ********************************************/
  #voice {
    text-align: center;
    
    .comment {
      width: 94vw;
      margin: 0 auto;
      padding: 60px 0;

      .comment_li {
        display: flex;
        flex-flow: row wrap;
        margin: 60px 0 0;

        li {
          width: 100%;
          margin: 0 0 20px 0;
          border: 2px solid v.$main-color;
          padding: 20px 17px 25px;

          h2 {
            font-size: 1.7rem;
            font-weight: 700;
            color: v.$main-color;
            text-align: left;
            margin: 0 0 10px;
          }

          .comment_li_txt {
            text-align: left;

            p {
              font-size: 1.5rem;
              margin: 10px 0;
            }
          }
        }
      }
    }
  }

  /*** よくあるご質問 *****************************************/
  #question {
    .faq {
      padding: 80px 0;

      .faq_li {
        margin: 60px 0 0;

        dt {
          position: relative;
          display: flex;
          align-items: flex-start;
          background: v.$main-color;
          color: v.$white;
          font-size: 1.6rem;
          padding: 10px 15px;
          font-weight: 400;
          z-index: 100;
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
          
          img {
            width: 26px;
            margin: 0 5px 0 0; 
          }
        }
        dd {
          display: flex;
          align-items: flex-start;
          background: v.$bg-gray2;
          margin: -10px 0 30px 0;
          padding: 30px 15px 25px;
          font-size: 1.4rem;

          img {
            width: 23px;
            margin: 0 8px 0 0;
          }

          span {
            padding: 5px 0 0;
          }
        }
      }
    }
  }

  /*** お手入れ通信 *******************************************/
  #oteire {
    .letter {
      text-align: center;
      padding: 80px 0;

      .letter_latest {
        border: 2px solid v.$main-color;
        padding: 10px 20px 15px;
        width: 100%;
        margin: 50px auto;
        
        p {
          margin: 5px 0 10px;
          color: v.$main-color;
          font-weight: 700;
          font-size: 1.6rem;
        }
        a {
          background: v.$main-color;
          color: v.$white;
          border-radius: 7px;
          display: inline-block;
          font-family: v.$sans;
          padding: 10px 25px;
          width: 100%;
        }
      }

      .letter_bn {
        display: flex;
        justify-content: stretch;
        align-items: center;
        margin: 0 0 25px;

        h3 {
          color: v.$main-color;
          font-weight: 700;
          font-size: 1.8rem;
          padding: 0 15px;
        }
        span {
          background: v.$main-color;
          height: 1px;
          width: auto;
          flex: 1;
        }
      }

      .letter_li {
        margin: 0 0 60px;
        
        table {
          width: 100%;

          tbody {
            tr {
              background: v.$blue1;

              th {
                width: 50%;
                padding: 6px 20px;
                vertical-align: middle;
              }
              td {
                width: 50%;
                text-align: right;
                color: v.$main-color;
                vertical-align: middle;
                padding: 6px 20px;
                font-weight: 700;
              }

              &:nth-child(even) {
                background: v.$blue2;
              }
            }
          }
        }
      }
    }
  }

  /*** お手入れビフォーアフター ********************************/
  #ba {
    .ex {
      text-align: center;
      padding: 60px 0 0;

      .ex_li {
        background: v.$bg-light;
        padding: 40px 0;
        margin: 40px 0 0;

        ul {
          li {
            text-align: left;
            margin: 0 0 10px;

            h3 {
              position: relative;
              font-size: 1.6rem;
              color: v.$main-color;
              font-weight: 700;
              padding: 0 0 0 20px;
              margin: 0 0 10px 0;
  
              &::before {
                position: absolute;
                left: 0;
                top: 12px;
                content: '';
                width: 15px;
                height: 15px;
                border-radius: 30px;
                background: v.$main-color;
              }
            }

            .ex_li_img {
              position: relative;
              display: flex;
              justify-content: space-between;
  
              figure {
                @include v.obf;
                position: relative;
                width: 45%;
                height: 45%;
  
                span {
                  position: absolute;
                  top: 0;
                  left: 0;
                  background: rgba(255, 255, 255, 0.7);
                  color: v.$main-color;
                  font-size: 1.4rem;
                  font-weight: 700;
                  padding: 1px 10px;
                }

                figcaption {
                  margin: 10px 0;
                }
              }

              &::before {
                position: absolute;
                top: 18vw;
                left: 50%;
                content: '';
                border-left: 3vw solid v.$main-color;
                border-top: 3vw solid transparent;
                border-bottom: 3vw solid transparent;
                transform: translateX(-45%);
              }
            }
          }
        }
      }
    }
  }

  /*** お知らせ **********************************************/
  #news {
    .topics {
      padding: 60px 0;

      .topics_li {
        background: v.$bg-gray2;
        padding: 20px 15px;

        li {
          border-bottom: 1px solid v.$main-color;

          a {
            display: block;
            padding: 12px 10px;
            font-family: v.$sans;

            time {
              padding: 0 0 5px;
              display: block;
              color: v.$main-color;
              font-weight: 700;
            }
          }

          &:last-of-type {
            border: none;
          }
        }
      }
    }

    .article {      
      padding: 100px 0 150px;
      

      .article_main {
        width: 100%;

        article {
          padding: 0 0 30px; 
          border-bottom: 3px solid v.$main-color;
          margin: 0 0 15px;

          .article_main_h {
            position: relative;
            border-left: 7px solid v.$main-color;
            border-bottom: 1px solid v.$main-color;
            padding: 0 10px 7px 15px;
            margin: 0 0 50px;
  
            h1 {
              font-size: 1.8rem;
              font-weight: 700;
            }
  
            time {
              font-size: 1.4rem;
              font-family: v.$sans;
            }
          }

          figure {
            margin: 30px auto;
            text-align: center;

            figcaption {
              font-size: 1.4rem;
              margin: 10px 0;
            }
            img {
              border: 1px solid v.$black;
            }
          }

          p {
            margin: 20px 0;
            font-size: 1.5rem;

            strong {
              color: v.$main-color;
            }
          }

          h2 {
            font-size: 2rem;
            color: v.$main-color;
            font-weight: 700;
            border-bottom: 1px solid v.$main-color;
          }
          
          h3 {
            position: relative;
            padding: 0 0 0 25px;
            font-weight: 700;
            font-size: 1.8rem;

            &::before {
              position: absolute;
              left: 0;
              top: 50%;
              content: '';
              width: 15px;
              height: 15px;
              background: v.$main-color;
              transform: translateY(-50%);
            }
          }

          h4 {
            color: v.$main-color;
            font-weight: 700;
            font-size: 1.7rem;
          }

          h5, h6 {
            font-weight: 700;
            font-size: 1.6rem;
          }

          ul {
            margin: 30px 0;
            padding: 0 0 0 30px;
            font-size: 1.5rem;
            
            li {
              list-style-type: disc;
            }
          }

          ol {
            margin: 30px 0;
            padding: 0 0 0 30px;

            li {
              list-style: decimal;
            }
          }

          table {
            width: 100%;
            border: 1px solid v.$main-color;
            font-family: v.$sans;

            thead {
              tr {
                th {
                  background: v.$bg-light;
                  text-align: center;
                  padding: 2px 10px;
                  border-bottom: 1px solid v.$main-color;
                  border-right: 1px solid v.$main-color;
                }
              }
            }
            tbody {
              tr {
                th {
                  padding: 2px 10px;
                  text-align: center;
                  border-bottom: 1px solid v.$main-color;
                  border-right: 1px solid v.$main-color;
                }
                td {
                  padding: 2px 10px;
                  border-bottom: 1px solid v.$main-color;
                  border-right: 1px solid v.$main-color;
                }
              }
            }
          }
        }

        .pnav {
          position: relative;
          font-size: 1.4rem;

          .pnav_prev {
            position: absolute;
            left: 0;
            top: 0;
            padding: 0 0 0 30px;
            color: v.$main-color;

            &::before {
              content: '';
              height: 4px;
              position: absolute;
              left: 0;
              top: 11px;
              width: 18px;
              background: v.$main-color;
            }
            &::after {
              content: '';
              width: 15px;
              height: 15px;
              border-left: 4px solid v.$main-color;
              border-bottom: 4px solid v.$main-color;
              transform: rotate(45deg);
              position: absolute;
              left: 0;
              top: 6px;
            }
          }

          .pnav_next {
            position: absolute;
            right: 0;
            top: 0;
            padding: 0 30px 0 0;
            color: v.$main-color;

            &::before {
              content: '';
              height: 4px;
              position: absolute;
              right: 0;
              top: 11px;
              width: 18px;
              background: v.$main-color;
            }
            &::after {
              content: '';
              width: 15px;
              height: 15px;
              border-right: 4px solid v.$main-color;
              border-bottom: 4px solid v.$main-color;
              transform: rotate(-45deg);
              position: absolute;
              right: 0;
              top: 6px;
            }
          }
        }
      }

      .article_side {
        width: 100%;

        h3 {
          font-size: 1.8rem;
          background: v.$main-color;
          text-align: center;
          padding: 3px 10px;
          color: v.$white;
        }

        .article_side_li {
          margin: 0 0 50px;

          li {
            a {
              display: block;
              border-bottom: 1px solid v.$main-color;
              padding: 12px 0;
            }
          }

          &.article_side_li-archive {
            text-align: center;
          }
        }
      }
    }
  }

  /*** お問い合わせ *******************************************/
  #contact {
    .intro {
      background: url('../images/about/bg_cloth_01.jpg') repeat center center / auto;
      padding: 30px 0;

      p {
        margin: 50px 0;
        text-align: left;
      }
    }

    .cform {
      padding: 70px 0;

      table {
        display: block;
        width: 100%;
        font-size: 1.5rem;

        tbody {
          display: block;

          tr {
            display: block;

            th {
              display: block;
              padding: 10px 0 0;
              font-family: v.$sans;

              span {
                background: v.$main-color;
                padding: 1px 5px 2px;
                color: v.$white;
                font-size: 1.2rem;
                font-family: v.$sans;
                display: inline-block;
                margin: 0 0 0 15px;
              }
            }
            td {
              display: block;
              padding: 10px 0;
              border-bottom: 1px solid v.$main-color;

              input[type="tel"],
              input[type="text"],
              input[type="email"],
              input[type="number"] {
                background: v.$bg-gray2;
                padding: 10px 15px;
                width: 100%;
                font-family: v.$sans;
              }

              select {
                width: 100%;
                border: 1px solid v.$main-color;
                padding: 10px;
                cursor: pointer;
              }

              input[type="date"] {
                width: 100%;
                border: 1px solid v.$main-color;
                padding: 10px;
                cursor: pointer;
              }

              textarea {
                background: v.$bg-gray2;
                padding: 10px 15px;
                width: 100%;
                font-family: v.$sans;
                height: 300px;
                resize: none;
              }
            }

            &.cform_name,
            &.cform_kana,
            &.cform_tel {
              td {
                input {
                  width: 100%;
                }
              }
            }

            &.cform_zip {
              td {
                input {
                  width: 60%;
                }
              }
            }

            &.cform_item {
              div {
                border-bottom: 1px solid v.$main-color;
                font-family: v.$sans;
                font-weight: 700;

                span {
                  display: block;
                }

                input[type="text"] {
                  width: 100%;
                  margin: 5px 0 15px;
                }
                input[type="number"] {
                  width: 50%;
                  margin: 5px 0 15px;
                }
                textarea {
                  width: 100%;
                  height: 90px;
                  margin: 5px 0 15px;
                }
              }
            }

            &:first-child {
              th {
                border-top: 1px solid v.$main-color;
              }
            }
          }
        }
      }

      .cform_btn {
        margin: 50px auto;

        input[type="submit"] {
          transition: all .3s;
          background: v.$main-color;
          color: v.$white;
          font-size: 1.8rem;
          padding: 12px;
          width: 100%;
          text-align: center;
          font-family: v.$sans;
          margin: 10px auto;
          border: 1px solid v.$main-color;

          &:hover {
            background: v.$white;
            color: v.$main-color;
          }
        }
      }
    }

    .thanks {
      padding: 70px 0;

      h2 {
        font-size: 2.4rem;
        font-weight: 700;
        color: v.$main-color;
        text-align: center;
        margin: 0 0 25px;
      }
    }

    .order {
      background: url('../images/about/bg_cloth_01.jpg') repeat center center / auto;
      padding: 80px 0;
    }
  }

  /*** 料金 **************************************************/
  #price {
    .intro {
      text-align: center;
      padding: 60px 0 0;
    }

    .plist {
      font-family: v.$sans;
      padding: 0 0 100px;

      h4 {
        position: relative;
        font-size: 2rem;
        color: v.$main-color;
        font-weight: 700;
        padding: 0 0 0 20px;
        margin: 40px 0 5px 0;

        &::before {
          position: absolute;
          left: 0;
          top: 12px;
          content: '';
          width: 15px;
          height: 15px;
          border-radius: 30px;
          background: v.$main-color;
        }
      }

      .plist_cnt {
        margin: 0 0 60px;
      }

      .plist_down {
        .plist_down_txt {
          width: 100%;
          margin: 15px 0 0;
          
          p {
            margin: 25px 0 0;
          }

          ul {
            margin: 7px 0;

            li {
              font-size: 1.4rem;

              strong {
                color: v.$main-color;
                font-weight: 700;
                font-size: 1.8rem;
              }
            }
          }
        }
      }
      /* テーブル共通 */
      .plist_tbl {
        width: 100%;
        border: 1px solid v.$main-color;
        font-size: 1.6rem;

        thead {
          tr {
            th {
              background: v.$blue3;
              border-right: 1px solid v.$main-color;
              border-bottom: 1px solid v.$main-color;
              padding: 5px 10px;
            }
          }
        }

        tbody {
          tr {
            th {
              width: 60%;
              border-right: 1px solid v.$main-color;
              border-bottom: 1px solid v.$main-color;
              padding: 5px 10px;
              background: v.$blue2;
            }
            td {
              width: 40%;
              border-right: 1px solid v.$main-color;
              border-bottom: 1px solid v.$main-color;
              padding: 5px 10px;
              background: v.$blue2;
            }

            &:nth-of-type(odd) {
              th, td {
                background: v.$blue1;
              }
            }
          }
        }
      }
      /* 注釈共通 */
      .plist_note {
        font-size: 1.3rem;
        margin: 10px 0;

        dl {
          dt {
            margin: 8px 0 0;
          }

          dd {
            strong {
              color: v.$main-color;
            }
          }
        }

        p {
          strong {
            color: v.$main-color;
          }
        }
      }
    }
  }

  /*** クリーニングコース **************************************/
  #course {
    .intro {
      text-align: center;
      padding: 60px 0 0;
    }

    .menu {
      .menu_about {
        background: v.$bg-gray2;
  
        figure {
          @include v.obf;
          width: 100%;
          height: auto;
        }
  
        .menu_about_txt {
          padding: 25px 25px;
        }
      }

      .menu_grade {
        margin: 60px 0;
        background: v.$bg-gray2;
        padding: 20px;

        p {
          font-size: 1.6rem;
          font-weight: 700;
          color: v.$main-color;
          margin: 0 0 10px;
        }

        .menu_grade_li {
          li {
            margin: 0 0 30px;

            .mgl_h {
              color: v.$main-color;
              font-weight: 700;
              text-align: center;

              h4 {
                font-size: 1.6rem;
                border: 1px solid v.$main-color;
                padding: 5px 5px;
              }
              span {
                font-size: 1.4rem;
                background: v.$white;
                display: block;
                padding: 0 10px;
                margin: 5px 0;
              }
              p {
                font-size: 1.5rem;
                color: v.$main-color;
                font-weight: 700;
                margin: 0;
                border: none;
                padding: 0;
                background: none;
              }
            }

            p {
              font-size: 1.4rem;
              font-weight: 400;
              color: v.$text-color;
              margin: 10px 0;
              background: v.$bg-blue;
              padding: 10px;
            }
          }
        }
      }

      .menu_tbl {
        border: 1px solid v.$main-color;
        width: 100%;
        font-size: 1.5rem;
        font-family: v.$sans;
        margin: 60px 0 0;

        thead {
          tr {
            th {
              background: v.$blue3;
              border-right: 1px solid v.$main-color;
              border-bottom: 1px solid v.$main-color;
              text-align: center;
              padding: 8px 5px;
              font-size: 1.4rem;

              &:first-child {
                display: none;
              }
            }
          }
        }
        tbody {
          tr {
            th {
              display: none;
            }
            td {
              background: v.$blue2;
              border-right: 1px solid v.$main-color;
              border-bottom: 1px solid v.$main-color;
              text-align: center;
              vertical-align: middle;
              padding: 8px 10px;

              &.min {
                font-size: 1.4rem;
                line-height: 1.5;
              }
            }

            &:nth-child(odd) {
              td {
                background: v.$blue1;
                width: 20%;
              }
            }
          }
        }
      }

      .menu_what {
        margin: 60px 0;

        div {
          background: v.$blue2;

          &:nth-of-type(odd) {
            background: v.$blue1;
          }

          dt {
            width: 100%;
            padding: 6px 10px 6px 10px;
            font-size: 1.5rem;
            border-bottom: 1px dashed v.$main-color;
          }
          dd {
            text-align: left;
            flex: 1;
            padding: 6px 10px 6px 10px;
            font-size: 1.4rem;
          }
        }
      }

      .menu_h {
        position: relative;
        font-size: 2rem;
        font-weight: 700;
        color: v.$main-color;
        padding: 0 0 0 30px;
        margin: 50px 0 15px;

        &::before {
          position: absolute;
          left: 0;
          top: 10px;
          content: '';
          width: 25px;
          height: 18px;
          background: url('../images/course/course_arrow2.svg') no-repeat center center / contain;
        }
      }

      .menu_flow {
        margin: 0 0 40px;

        li {
          position: relative;
          background: linear-gradient(90deg, v.$main-color 0%, #77b7e5 100%);
          color: v.$white;
          text-align: center;
          padding: 10px 10px;
          font-size: 1.6rem;
          margin: 30px 0;

          &::after {
            position: absolute;
            content: '';
            width: 21px;
            height: 25px;
            background: url('../images/course/course_arrow1.svg') no-repeat center center / contain;
            left: 50%;
            bottom: -27px;
            transform: translateX(-50%) rotate(90deg);
          }

          &:last-of-type {
            &::after {
              display: none;
            }
          }
        }
      }

      .menu_detail {
        margin: 0 0 20px;

        .menu_detail_one {
          border: 1px solid v.$main-color;
          width: 100%;
          margin: 0 0 20px 0;
          padding: 10px 15px;

          dt {
            font-size: 1.8rem;
            color: v.$main-color;
            border-bottom: 1px solid v.$main-color;
            padding: 0 0 5px;
            margin: 0 0 8px;
          }

          dd {
            font-size: 1.4rem;
          }
        }
      }

      .menu_btn {
        text-align: center;
        margin: 20px 0 100px;

        a {
          position: relative;
          background: v.$main-color;
          color: v.$white;
          border: 1px solid v.$main-color;
          padding: 20px 10px;
          display: block;

          &::after {
            content: '';
            position: absolute;
            right: 20px;
            top: 50%;
            border-right: 1px solid v.$white;
            border-bottom: 1px solid v.$white;
            transform: translateY(-50%) rotate(-45deg);
            width: 8px;
            height: 8px;
          }
        }
      }

      .menu_note {
        margin: 20px 0;
        
        p {
          font-size: 1.4rem;
        }
      }
    }
    

  }

}

@media screen and (max-width: 768px) and (orientation: landscape) and (max-width: 767px) {

  .main-visual {
    height: 320px;

    .swiper-container {
      height: 320px;
    }

    .flex-container {
      width: 90%;
      height: auto;
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      align-items: flex-end;

      .text-wrap {
        -ms-writing-mode: lr-tb;
        writing-mode: horizontal-tb;

        p {
          background: v.$white;
          color: v.$main-color;
          font-size: 2.4rem;
          display: inline-block;
          font-weight: bold;
          line-height: 1.6;
          padding: 0 15px;
          margin: 0 0 10px;
        }
      }

      .btn {
        width: 150px;
      }
    }
  }

}